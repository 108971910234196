import * as React from "react";
import Slider from "react-slick";

import {
  FractionalCTOContainer,
  CardContainer,
  MobileHeading,
  StyledHeading,
  StyledHeadingLanding,
  AdvisoryContentContainer,
  PerkContainer,
  Description,
  Subtitle,
  TextContainer,
  TestimonailAuthor,
  TestimonialImage,
  TestimonialText,
  StyledCard,
  PerkIcon,
  TestimonialContainer,
  AdvisoryImageContainer,
  StyledAnchor,
  StyledButton,
  AuthorTitle,
  AuthorSubtitle,
  PerkContainerTitle,
} from "./FractionalCTO.styles";
import useMediaQuery from "../../hooks/useMediaQuery";
import { size } from "../../theme/sizes";
import { Paragraph } from "../Typography/Typography";
import { useFemaleFounderQuery } from "../../hooks";
import { sliderCommonSettings } from "../../utils";

import AdvisoryImage from "../../images/advisory_female_founders.png";
import PitchDeckImage from "../../images/content_icons/pitch_deck_development.png";
import MVPDevelopmentImage from "../../images/content_icons/mvp_development.png";
import StandinCTOImage from "../../images/content_icons/custom_team_buiding.png";
import InnovationSupportImage from "../../images/content_icons/innovation_support.png";
import TechnicalInfraImage from "../../images/content_icons/technical_infra.png";
import TechnicalExpertiseImage from "../../images/content_icons/tech_expert.png";
import ProductDesignImage from "../../images/content_icons/product_development_oversight.png";
import UIUXImage from "../../images/content_icons/ui_ux_valuation.png";
import DocumentaionImage from "../../images/content_icons/docs.png";
import DueDiligenceImage from "../../images/content_icons/due_diligence.png";

const FractionalCTO = () => {
  const isMobile = useMediaQuery(size.tabletL);
  const testimonials = useFemaleFounderQuery();

  const sliderConfig = {
    ...sliderCommonSettings,
    slidesToShow: 1,
  };

  return (
    <FractionalCTOContainer id="#fractional-cto">
      <AdvisoryContentContainer>
        {isMobile && (
          <MobileHeading weight="semiBold">
            The Challenge: Establishing credibility for Non-technical Founders
          </MobileHeading>
        )}
        <AdvisoryImageContainer src={AdvisoryImage} alt="build-an-mvp" />
        <PerkContainer>
          {!isMobile && (
            <StyledHeadingLanding weight="semiBold">
              The Challenge: Establishing credibility for Non-technical Founders
            </StyledHeadingLanding>
          )}
          <Paragraph>
            Non-technical founders often encounter unique challenges when seeking support
            from venture capitalists (VCs) and engaging with the broader tech community.
            In a landscape dominated by technical expertise and industry jargon, non-technical
            founders may struggle to establish credibility and garner interest in their startup ideas.
            We are here to guide you through your tech journey.
          </Paragraph>
        </PerkContainer>
      </AdvisoryContentContainer>

      <PerkContainer>
        <PerkContainerTitle weight="semiBold">Our Solution</PerkContainerTitle>
        <CardContainer>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={PitchDeckImage} alt="icon" />
              Pitch Deck Development
            </Subtitle>
            <Description>
              We will advise you on how to create a pitch deck that not only
              tells your story but also grabs investors’ attention.
            </Description>
          </StyledCard>

          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={MVPDevelopmentImage} alt="icon" />
              MVP Development
            </Subtitle>
            <Description>
              We will help you turn your ideas into fully functional products
              (see our MVP development service).
            </Description>
          </StyledCard>

          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={StandinCTOImage} alt="icon" />
              Fractional CTO/CPO
            </Subtitle>
            <Description>
              Our Founder can join your team as a fractional CTO or CPO to give
              your investors confidence that there is someone with significant
              senior-level experience behind the scene.
            </Description>
          </StyledCard>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={InnovationSupportImage} alt="icon" />
              Innovation Support
            </Subtitle>
            <Description>
              Whether it’s enhancing an existing product or envisioning the next
              big thing, we help you move your product to the future state of
              innovation.
            </Description>
          </StyledCard>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={ProductDesignImage} alt="icon" />
              Product Design Assistance
            </Subtitle>
            <Description>
              We will dive deep into understanding your products, creating
              wireframes, and collaborating with UX designers to develop
              prototypes that align with your vision.
            </Description>
          </StyledCard>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={TechnicalInfraImage} alt="icon" />
              Understanding and Building Technical Infrastructures
            </Subtitle>
            <Description>
              From designing cloud-based web systems to architecting scalable
              solutions, alphathesis can help you build and understand robust
              technical infrastructures.
            </Description>
          </StyledCard>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={TechnicalExpertiseImage} alt="icon" />
              Technical Expertise
            </Subtitle>
            <Description>
              We will dive deep into understanding your products, creating
              wireframes, and collaborating with UX designers to develop
              prototypes that align with your vision.
            </Description>
          </StyledCard>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={UIUXImage} alt="icon" />
              UI/UX Evaluation
            </Subtitle>
            <Description>
              Let us evaluate your products and assess the viability of their
              user interfaces and experiences. Our evaluation will help you
              refine and enhance them.
            </Description>
          </StyledCard>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={DocumentaionImage} alt="icon" />
              Comprehensive Documentation
            </Subtitle>
            <Description>
              From data model diagrams to technical roadmaps, we’ll ensure that
              your project is well-documented every step of the way.
            </Description>
          </StyledCard>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={DueDiligenceImage} alt="icon" />
              Due Diligence Support
            </Subtitle>
            <Description>
              We can help you vet vendors and liaise with key stakeholders in
              partner organizations, ensuring that your partnerships are
              strategically aligned and well informed.
            </Description>
          </StyledCard>
        </CardContainer>
        <StyledAnchor
          target="_blank"
          href="https://calendly.com/alphathesis/30min"
        >
          <StyledButton
            label="I am Interested"
            variant="primary"
            weight="semiBold"
          />
        </StyledAnchor>
      </PerkContainer>

      <StyledHeading weight="semiBold">
        Hear what our Founders have to say about alphathesis!
      </StyledHeading>

      <Slider {...sliderConfig} style={{ width: "100%", margin: "0" }}>
        {testimonials.map((node, index: number) => (
          <TextContainer key={index}>
            <TestimonialContainer>
              {!isMobile && (
                <TestimonialImage
                  src={node.linkedInProfileImageLink}
                  alt={node.fullName}
                />
              )}
              {isMobile && (
                <TestimonialImage
                  src={node.linkedInProfileImageLink}
                  alt={node.fullName}
                />
              )}
              <TestimonailAuthor>
                <AuthorTitle weight="semiBold">{node.fullName}</AuthorTitle>
                <AuthorSubtitle weight="semiBold">
                  {node.title}, {node.companyName}
                </AuthorSubtitle>
              </TestimonailAuthor>
            </TestimonialContainer>
            <TestimonialText>{node.testimonial.testimonial}</TestimonialText>
          </TextContainer>
        ))}
      </Slider>
    </FractionalCTOContainer>
  );
};

export default FractionalCTO;
