import styled from "styled-components";
import { device } from "../../theme/sizes";
import Button from "../Button/Button";
import { StyledParagraph } from "../Button/Button.styles";
import { Heading, Paragraph } from "../Typography/Typography";
import { Link } from "gatsby";

export const FractionalCTOContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-direction: column;

  @media ${device.tabletL} {
    padding: 0;
    padding: 60px 0;
  }
`;

export const AdvisoryContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-direction: row-reverse;

  @media ${device.tabletL} {
    flex-direction: column;
  }
`;

export const StyledHeadingLanding = styled(Heading)`
  text-align: left;
  padding: 0.4em 0;

  @media ${device.tabletL} {
    text-align: left;
    font-size: 32px;
    line-height: 40px;
  }
`;

export const StyledHeading = styled(Heading)`
  text-align: center;

  @media ${device.tabletL} {
    text-align: left;
    font-size: 32px;
    line-height: 40px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MobileHeading = styled(Heading)`
  font-size: 32px;
  line-height: 40px;
`;

export const AdvisoryImageContainer = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 572px;
  @media ${device.tabletL} {
    height: auto;
  }
`;

export const PerkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin: 24px 0;
  @media ${device.tabletL} {
    margin: 0;
  }
`;

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 46%;
  margin: auto;
  align-items: left;

  @media ${device.tabletL} {
    width: 100%;
  }
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;

  @media ${device.tabletL} {
    width: 24px;
  }
`;

export const PerkIcon = styled.img`
  width: 40px;
  height: 40px;
  margin: auto 12px auto 0;

  @media ${device.tabletL} {
    width: 32px;
    height: 32px;
  }
`;

export const Description = styled(Paragraph)`
  font-size: 16px;
  line-height: 26px;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 260px);
  padding-top: 1.5em;
  gap: 8px;

  @media ${device.tabletL} {
    grid-template-columns: repeat(1, 1fr);
    padding-top: 0;
  }
`;

export const CTOCard = styled.div`
  color: #fff;
  padding: 10px;
  text-align: center;
  height: fit-content;

  @media ${device.tabletL} {
    padding: 0;
    margin: 1em 0;
  }
`;

export const CTOCardTitle = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 1em;
`;

export const StyledCardHeading = styled(Heading)`
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  padding-left: 0.5em;

  @media ${device.tabletL} {
    font-size: 18px;
  }
`;

export const CTOCardImage = styled.img`
  width: 48px;
  height: 48px;
`;

export const StyledText = styled(Paragraph)`
  text-align: left;
  @media ${device.tabletL} {
    font-size: 16px;
    line-height: 26px;
  }
`;

export const StyledAnchor = styled.a`
  margin: 2em auto;
  @media ${device.tabletL} {
    margin-top: 12px;
    display: block;
    width: 100%;
    text-align: center;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  aligh-self: flex-end;
  float: right;

  @media ${device.tabletL} {
    margin: auto;
  }
`;

export const StyledButton = styled(Button)`
  ${StyledParagraph} {
    font-size: 20px;
    line-height: 28px;
  }

  @media ${device.tabletL} {
    width: 142px;
    padding: 10px 0;

    ${StyledParagraph} {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const Subtitle = styled(Paragraph)`
  display: flex;
  margin-bottom: 8px;
`;

export const TestimonailAuthor = styled.div`
  margin: 1em 0;
`;

export const TestimonialText = styled(Paragraph)`
  font-style: italic;
  margin: 10px auto;
  padding: 10px;
  max-width: 1080px;

  @media ${device.tabletL} {
    padding: 2em 0;

    ${StyledParagraph} {
      font-size: 16px;
      line-height: 20px;
    }
  }
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

export const TestimonialImage = styled.img`
  width: 240px;
  border-radius: 50%;
  margin: 1em auto;
`;

export const TextContainer = styled.div`
  flex-direction: column;
  text-align: center;
  width: 100%;
  @media ${device.tabletL} {
    width: 100%;
    padding: 0;

    ${Paragraph} {
      font-size: 16px;
      line-height: 32px;
    }
  }
`;

export const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AuthorTitle = styled(Paragraph)`
  margin: 0 0 1em 0;
  font-size: 36px;
`;

export const AuthorSubtitle = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.gray};
`;

export const PerkContainerTitle = styled(Heading)`
  margin: 0 auto;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.gray};

  @media ${device.tabletL} {
    margin: 1em 0;
    font-size: 24px;
  }
`;
