import * as React from "react";
import Layout from "../../components/Layout/Layout";

import Footer from "../../components/Footer";
import ScrollHandler from "../../components/ScrollHander";
import FractionalCTO from "../../components/FractionalCTO";

const FractionalCTOPage = () => (
  <>
    <ScrollHandler />
    <Layout>
      <FractionalCTO />
      <Footer />
    </Layout>
  </>
);

export default FractionalCTOPage;
